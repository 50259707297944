
<template>
    <div>
        <form @submit.prevent class="mt-12 sm:max-w-lg sm:w-full sm:flex">
            <div class="min-w-0 flex-1">
                <label for="hero-email" class="sr-only">Email address</label>
                <input id="hero-email" v-model="email" type="email"
                    class="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    placeholder="Enter your email" />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-3">
                <button @click="subscribeEmail" type="submit"
                    class="block w-full rounded-md border border-transparent px-5 py-3 bg-primary-500 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10">
                    Notify
                    me
                </button>
            </div>
        </form>
        <div v-if="success" class="rounded-md bg-green-50 p-4 mt-2">
            <div class="flex">
                <div class="flex-shrink-0">
                    <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">Thanks! Please check your email to confirm your
                        subscription.</p>
                </div>
                <div class="ml-auto pl-3">
                    <div class="-mx-1.5 -my-1.5">
                        <button @click="success = false" type="button"
                            class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                            <span class="sr-only">Dismiss</span>
                            <XIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

const email = ref('')

const success = ref(false)

const subscribeEmail = async () => {

    const { data: subscribeResponse } = await useFetch(`${config.public['WETIX_SERVICE_API_BASE_URL']}/v1/public/subscribe-wetix-mailing-list`,
        {
            method: 'POST',
            headers: useRequestHeaders(['cookie']),
            body: {
                subscriber: {
                    email: unref(email),
                    windowLocation: `${window.location}`,
                }

            }
        }) as any

    if (subscribeResponse.value?.status === 'success') {
        console.log(`Successfully subscribed to the mailing list`)
        success.value = true
    }

    console.log(subscribeResponse.value)
}

</script>

<script lang="ts">
import { CheckCircleIcon, XIcon } from '@heroicons/vue/solid'

export default {

    components: {
        CheckCircleIcon,
        XIcon,
    },

}
</script>