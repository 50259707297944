
<template>
    <div>
        <NavSiteHeader />

        <div class="bg-white">

            <main>
                <!-- Hero section -->
                <div class="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
                    <div
                        class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                        <div>
                        <!-- <div>
                                <img class="h-8 w-auto" src="~/assets/wetix-logo.svg" alt="WeTix" />
                                </div> -->
                            <div class="mt-10">
                                <div>
                                    <a href="#" class="inline-flex space-x-4">
                                        <span
                                            class="rounded bg-primary-50 px-2.5 py-1 text-xs font-semibold text-primary-500 tracking-wide uppercase">In
                                            Beta</span>
                                        <span
                                            class="inline-flex items-center text-sm font-medium text-primary-500 space-x-1">
                                            <span>Get in touch</span>
                                            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </a>
                                </div>
                                <div class="mt-6 sm:max-w-xl">
                                    <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">The
                                        complete event management platform.</h1>
                                    <p class="mt-6 text-xl text-gray-500">WeTix is a cutting-edge event management,
                                        ticketing and marketing platform for event operators of all sizes. We're
                                        currently
                                        in closed beta; get in touch today to see how we can make your events more
                                        profitable .</p>
                                </div>
                                <MarketingSubscribeWetixEmail></MarketingSubscribeWetixEmail>
                                <form action="#" class="mt-12 sm:max-w-lg sm:w-full sm:flex">
                                <!-- <div class="min-w-0 flex-1">
                                    <label for="hero-email" class="sr-only">Email address</label>
                                    <input
                                        id="hero-email"
                                        type="email"
                                        class="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <div class="mt-4 sm:mt-0 sm:ml-3">
                                    <button
                                        type="submit"
                                        class="block w-full rounded-md border border-transparent px-5 py-3 bg-primary-500 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10"
                                    >Notify me</button>
                                    </div>-->
                                </form>
                                <div class="mt-6">
                                    <div class="inline-flex items-center divide-x divide-gray-300">
                                        <div class="flex-shrink-0 flex pr-5">
                                            <StarIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            <StarIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                        </div>
                                        <div class="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                                            <span class="font-medium text-gray-900">Rated 5 stars</span> by over
                                            <span class="font-medium text-primary-500">500 beta users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
                        <div class="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                            <div class="hidden sm:block">
                                <div
                                    class="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                                <svg class="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0" width="404" height="392"
                                    fill="none" viewBox="0 0 404 392">
                                    <defs>
                                        <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20"
                                            height="20" patternUnits="userSpaceOnUse">
                                            <rect x="0" y="0" width="4" height="4" class="text-gray-200"
                                                fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                                </svg>
                            </div>
                            <div
                                class="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                                <img class="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                                    src="~/assets/wetix-mockup-2.png" alt />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Testimonial/stats section -->
            <!-- <div class="relative mt-20">
                <div
                    class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start"
                >
                    <div class="relative sm:py-16 lg:py-0">
                        <div
                            aria-hidden="true"
                            class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
                        >
                            <div
                                class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"
                            />
                            <svg
                                class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                                width="404"
                                height="392"
                                fill="none"
                                viewBox="0 0 404 392"
                            >
                                <defs>
                                    <pattern
                                        id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                        x="0"
                                        y="0"
                                        width="20"
                                        height="20"
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect
                                            x="0"
                                            y="0"
                                            width="4"
                                            height="4"
                                            class="text-gray-200"
                                            fill="currentColor"
                                        />
                                    </pattern>
                                </defs>
                                <rect
                                    width="404"
                                    height="392"
                                    fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                                />
                            </svg>
                        </div>
                        <div
                            class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20"
                        >
                           
                            <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                <img
                                    class="absolute inset-0 h-full w-full object-cover"
                                    src="https://images.unsplash.com/photo-1521510895919-46920266ddb3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&fp-x=0.5&fp-y=0.6&fp-z=3&width=1440&height=1440&sat=-100"
                                    alt
                                />
                                <div class="absolute inset-0 bg-primary-500 mix-blend-multiply" />
                                <div
                                    class="absolute inset-0 bg-gradient-to-t from-primary-600 via-primary-600 opacity-90"
                                />
                                <div class="relative px-8">
                                    <div>
                                        <img
                                            class="h-12"
                                            src="https://tailwindui.com/img/logos/workcation.svg?color=white"
                                            alt="Workcation"
                                        />
                                    </div>
                                    <blockquote class="mt-8">
                                        <div
                                            class="relative text-lg font-medium text-white md:flex-grow"
                                        >
                                            <svg
                                                class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-primary-400"
                                                fill="currentColor"
                                                viewBox="0 0 32 32"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
                                                />
                                            </svg>
                                            <p
                                                class="relative"
                                            >Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet.</p>
                                        </div>

                                        <footer class="mt-4">
                                            <p
                                                class="text-base font-semibold text-primary-200"
                                            >Sarah Williams, CEO at Workcation</p>
                                        </footer>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                        
                        <div class="pt-12 sm:pt-16 lg:pt-20">
                            <h2
                                class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl"
                            >On a mission to empower teams</h2>
                            <div class="mt-6 text-gray-500 space-y-6">
                                <p
                                    class="text-lg"
                                >Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi. Cursus faucibus nunc nisl netus morbi vel porttitor vitae ut. Amet vitae fames senectus vitae.</p>
                                <p
                                    class="text-base leading-7"
                                >Sollicitudin tristique eros erat odio sed vitae, consequat turpis elementum. Lorem nibh vel, eget pretium arcu vitae. Eros eu viverra donec ut volutpat donec laoreet quam urna. Sollicitudin tristique eros erat odio sed vitae, consequat turpis elementum. Lorem nibh vel, eget pretium arcu vitae. Eros eu viverra donec ut volutpat donec laoreet quam urna.</p>
                                <p
                                    class="text-base leading-7"
                                >Rhoncus nisl, libero egestas diam fermentum dui. At quis tincidunt vel ultricies. Vulputate aliquet velit faucibus semper. Pellentesque in venenatis vestibulum consectetur nibh id. In id ut tempus egestas. Enim sit aliquam nec, a. Morbi enim fermentum lacus in. Viverra.</p>
                            </div>
                        </div>

                       
                        <div class="mt-10">
                            <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
                                <div
                                    v-for="stat in stats"
                                    :key="stat.label"
                                    class="border-t-2 border-gray-100 pt-6"
                                >
                                    <dt class="text-base font-medium text-gray-500">{{ stat.label }}</dt>
                                    <dd
                                        class="text-3xl font-extrabold tracking-tight text-gray-900"
                                    >{{ stat.value }}</dd>
                                </div>
                            </dl>
                            <div class="mt-10">
                                <a
                                    href="#"
                                    class="text-base font-medium text-primary-500"
                                >Learn more about how we're changing the world&nbsp&rarr;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>-->

                <!-- Logo cloud section -->
            <!-- <div class="mt-32">
                <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                    <div class="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
                        <div>
                            <h2
                                class="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl"
                            >Backed by world-renowned investors</h2>
                            <p
                                class="mt-6 max-w-3xl text-lg leading-7 text-gray-500"
                            >Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique pellentesque. Blandit amet, sed aenean erat arcu morbi. Cursus faucibus nunc nisl netus morbi vel porttitor vitae ut. Amet vitae fames senectus vitae.</p>
                            <div class="mt-6">
                                <a
                                    href="#"
                                    class="text-base font-medium text-primary-500"
                                >Meet our investors and advisors&nbsp&rarr;</a>
                            </div>
                        </div>
                        <div
                            class="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2"
                        >
                            <div
                                v-for="logo in logos"
                                :key="logo.name"
                                class="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
                            >
                                <img class="max-h-12" :src="logo.url" :alt="logo.name" />
                            </div>
                        </div>
                    </div>
                </div>
                </div>-->

                <!-- CTA section -->
            <!-- <div class="relative mt-24 sm:mt-32 sm:py-16">
                <div aria-hidden="true" class="hidden sm:block">
                    <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
                    <svg
                        class="absolute top-8 left-1/2 -ml-3"
                        width="404"
                        height="392"
                        fill="none"
                        viewBox="0 0 404 392"
                    >
                        <defs>
                            <pattern
                                id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                                patternUnits="userSpaceOnUse"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="4"
                                    height="4"
                                    class="text-gray-200"
                                    fill="currentColor"
                                />
                            </pattern>
                        </defs>
                        <rect
                            width="404"
                            height="392"
                            fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                        />
                    </svg>
                </div>
                <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div
                        class="relative rounded-2xl px-6 py-10 bg-primary-500 overflow-hidden shadow-xl sm:px-12 sm:py-20"
                    >
                        <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                            <svg
                                class="absolute inset-0 h-full w-full"
                                preserveAspectRatio="xMidYMid slice"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 1463 360"
                            >
                                <path
                                    class="text-primary-400 text-opacity-40"
                                    fill="currentColor"
                                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                                />
                                <path
                                    class="text-primary-600 text-opacity-40"
                                    fill="currentColor"
                                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                                />
                            </svg>
                        </div>
                        <div class="relative">
                            <div class="sm:text-center">
                                <h2
                                    class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl"
                                >Get notified when we&rsquo;re launching.</h2>
                                <p
                                    class="mt-6 mx-auto max-w-2xl text-lg text-primary-100"
                                >Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique pellentesque.</p>
                            </div>
                            <form action="#" class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                                <div class="min-w-0 flex-1">
                                    <label for="cta-email" class="sr-only">Email address</label>
                                    <input
                                        id="cta-email"
                                        type="email"
                                        class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-500"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <div class="mt-4 sm:mt-0 sm:ml-3">
                                    <button
                                        type="submit"
                                        class="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-500 sm:px-10"
                                    >Notify me</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>-->
            </main>

            <!-- Footer section -->
            <footer class="mt-4 bg-gray-50 sm:mt-0">
                <div class="mx-auto max-w-md py-4 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <!-- <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    <div v-for="item in footerNavigation.main" :key="item.name" class="px-5 py-2">
                        <a
                            :href="item.href"
                            class="text-base text-gray-400 hover:text-gray-300"
                        >{{ item.name }}</a>
                    </div>
                    </nav>-->
                <!-- <div class="mt-8 flex justify-center space-x-6">
                    <a
                        v-for="item in footerNavigation.social"
                        :key="item.name"
                        :href="item.href"
                        class="text-gray-400 hover:text-gray-300"
                    >
                        <span class="sr-only">{{ item.name }}</span>
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                    </a>
                    </div>-->
                    <p class="mt-8 text-center text-gray-600 text-xs">
                        &copy; 2022 WeTix Ltd - All rights reserved <br /> WeTix™ is a registered trademark. <br />

                    </p>
                    <p class="mt-8 text-center text-base text-black-400">

                        <NuxtLink to="/login">
                            <span class="opacity-50 font-medium">Beta Login</span>
                        </NuxtLink>
                    </p>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { defineComponent, h } from 'vue'
import { ChevronRightIcon, StarIcon } from '@heroicons/vue/solid'

const stats = [
    { label: 'Founded', value: '2021' },
    { label: 'Employees', value: '5' },
    { label: 'Beta Users', value: '521' },
    { label: 'Raised', value: '$25M' },
]
const logos = [
    { name: 'Transistor', url: 'https://tailwindui.com/img/logos/transistor-logo-gray-400.svg' },
    { name: 'Mirage', url: 'https://tailwindui.com/img/logos/mirage-logo-gray-400.svg' },
    { name: 'Tuple', url: 'https://tailwindui.com/img/logos/tuple-logo-gray-400.svg' },
    { name: 'Laravel', url: 'https://tailwindui.com/img/logos/laravel-logo-gray-400.svg' },
    { name: 'StaticKit', url: 'https://tailwindui.com/img/logos/statickit-logo-gray-400.svg' },
    { name: 'Workcation', url: 'https://tailwindui.com/img/logos/workcation-logo-gray-400.svg' },
]
const footerNavigation = {
    main: [
        { name: 'About', href: '#' },
        { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Accessibility', href: '#' },
        { name: 'Partners', href: '#' },
    ],
    social: [
        {
            name: 'Facebook',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d:
                                'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Instagram',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d:
                                'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Twitter',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            d:
                                'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
                        }),
                    ]),
            }),
        },
        {
            name: 'GitHub',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d:
                                'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Dribbble',
            href: '#',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d:
                                'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
    ],
}

export default {
    layout: 'sitedefault',
    components: {
        ChevronRightIcon,
        StarIcon,
    },
    setup() {
        return {
            stats,
            logos,
            footerNavigation,
        }
    },
}
</script>